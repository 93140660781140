import { createOwnUpComponent, createOwnUpStyle } from '@rategravity/own-up-component-library';
import React, { PropsWithChildren } from 'react';
import { colors } from '../../modules/colors';

const SectionStyle = createOwnUpStyle({
  display: 'flex',
  margin: '0 30px',
  backgroundColor: colors.LIGHT_GREY,
  variants: {
    smallAndDown: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  }
});

const Section = createOwnUpComponent('section', SectionStyle);

const FirstStyle = createOwnUpStyle({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '50%',
  backgroundColor: colors.LIGHT_GREY,
  variants: {
    mediumAndDown: {
      width: '100%'
    }
  }
});

const First = createOwnUpComponent('div', FirstStyle);

const SecondStyle = createOwnUpStyle({
  width: '50%',
  variants: {
    mediumAndDown: {
      width: '100%'
    }
  }
});

const Second = createOwnUpComponent('div', SecondStyle);

const ContentWrapperStyle = createOwnUpStyle({
  padding: '64px',
  marginRight: '40px',
  boxSizing: 'border-box',
  variants: {
    mediumAndDown: {
      padding: '30px',
      margin: 0,
      width: '100%'
    }
  }
});

const ContentWrapper = createOwnUpComponent('div', ContentWrapperStyle);

export const imgStyle = {
  height: '100%',
  margin: 'auto',
  position: 'relative' as const
};

export const GreyFieldWithPhoto = ({
  content,
  children
}: PropsWithChildren<{ content: JSX.Element }>) => {
  return (
    <Section aria-label="featured text and photo">
      <First>
        <ContentWrapper>{content}</ContentWrapper>
      </First>
      <Second>{children}</Second>
    </Section>
  );
};
